.App {
  height: 100vh;
  width: 100%;
  text-align: center;
  background: #2980B9;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FFFFFF, #6DD5FA, #2980B9);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FFFFFF, #6DD5FA, #2980B9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  overflow: hidden;
}

img.slider-image {
  display: block;
  max-width: 360px;
  max-height: 150px;
  width: auto;
  height: auto;
}