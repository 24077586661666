div.criteria-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 375px;
  overflow: auto;
}

div.criteria-page_cards {
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  min-height: 250px;
}

@media only screen and (max-width: 900px) {
  div.criteria-page_cards {
    min-height: 700px;
  }
}

div.criteria-page_cards .card {
  margin: 10px;
  height: 220px;
}

@media only screen and (max-width: 370px) {
  div.criteria-page {
    height: 295px;
    width: 100%;
  }
}
