.hidden {
  display: none;
}

.blinking {
    animation: blinkingText 1s infinite ease-in-out;
    font-family: futura;
    font-style: italic;
    width: 100%;
    height: 75px;
    margin: 0 auto;
    text-align: center;
    color: white;
    font-size: 1rem;
    background-color: #fc030b;
    position: absolute;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.close-button {
  position: absolute;
  top: 65px;
  right: 0px;
  background-color: lightgrey;
  border-radius: 10px;
  margin: 0 auto;
  cursor: pointer;
}
        
@keyframes blinkingText {
    from {
        opacity: 1;
        color : yellow;
        }
    to {
        opacity: 0;
        color : white;
        }
    0% {
        opacity: .5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: .5;
    }
}