p {
  font-family: unset;
  font-size: 1rem;
  white-space: pre-wrap;
  text-align: left;
}

h1 {
  border-bottom: solid thin grey;
}

.introduction-page-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 15%;
}

.introduction-page-header img {
  height: 20px;
  width: 20px;
  cursor: pointer;
  z-index: 999;
}

p.introduction-page-ending {
  text-align: right;
}

p.introduction-page-signature {
  text-align: right;
  font-family: Snell Roundhand, cursive;
  font-size: 1.2rem;
  font-weight: bold;
}

@media only screen and (max-width: 450px) {
  .introduction-page-header span {
    font-size: 1.2rem;
  }
  .introduction-page-text p {
    font-size: 0.5rem;
  }
}
