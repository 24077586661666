p.title-page-company-name {
  font-size: 2rem;
  font-family: Brush Script MT, Brush Script Std, cursive;
  position: relative;
  bottom: -30px;
}

div.title-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

img.title-page-img {
  position: relative;
  bottom: -120px;
  height: 65%;
  width: 65%;
  max-height: 500px;
  max-width: 300px;
}

@media only screen and (max-width: 450px) {
  img.title-page-img {
    bottom: -50px;
  }
  p.title-page-company-name {
    font-size: 1.5rem;
    bottom: -20px;
  }
}

@media only screen and (max-width: 375px) {
  p.title-page-company-name {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 330px) {
  p.title-page-company-name {
    font-size: 1.2rem;
    bottom: -20px;
  }
}