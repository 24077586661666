body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 300px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

div.slick-slider.slick-initialized[dir="ltr"]:nth-child(2){
  height: 500px;
}

div.slick-slider:nth-child(2) div.slick-list {
  transition: all 0.5s;
  overflow-y: hidden;
  height: 500px;
}

ul.slick-dots {
  position: absolute;
  bottom: -10%;
}

.slider-next-prev {
  width: 100px;
  position: absolute;
  display: flex;
  bottom: 50px;
  right: 39%;
  justify-content: space-between;
  margin: auto;
  display: none; /* see about turning this off */
}

@media only screen and (max-width: 2000px) {
  ul.slick-dots {
    bottom: -15%;
  }
  .slider-next-prev {
    bottom: 5%;
  }
}

@media only screen and (max-width: 420px) {
  ul.slick-dots {
    bottom: -15%;
  }
  .slider-next-prev {
    bottom: 5%;
  }
}

@media only screen and (max-width: 380px) {
  ul.slick-dots {
    bottom: -15%;
  }
  .slider-next-prev {
    bottom: 5%;
  }
}

@media only screen and (max-width: 330px) {
  ul.slick-dots {
    bottom: 37%;
  }

  .slider-next-prev {
    bottom: 30%;
  }
}

@media only screen and (max-width: 325px) {
  ul.slick-dots {
    bottom: 20%;
  }
}

@media only screen and (max-width: 320px) {
  ul.slick-dots {
    bottom: -15%;
  }
}

.slick-slide {
  z-index: 0;
}

.slick-slide.slick-active.slick-center.slick-current {
  z-index: 999999;
}

ul.slick-dots li {
  width: 30px;
}

ul.slick-dots .slick-active {
  background-color: lightgrey;
  border-radius: 15px;
}
