.table-of-contents {
  height: 375px;
  width: 65%;
  margin: 0 auto;
  overflow: auto;
  border-radius: 20px;
  background: #8e9eab; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #eef2f3, #8e9eab); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #eef2f3, #8e9eab); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

@media only screen and (max-width: 2000px) {
  .table-of-contents {
    height: 400px;
    width: 100%;
  }
}

@media only screen and (max-width: 780px) {
  .table-of-contents {
    height: 500px;
  }
}

@media only screen and (max-width: 420px) {
  .table-of-contents {
    height: 350px;
  }
}

@media only screen and (max-width: 380px) {
  .table-of-contents {
    height: 300px;
  }
}

@media only screen and (max-width: 330px) {
  .table-of-contents {
    height: 250px;
  }
}

.table-of-contents_item {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  padding: 10px;
}

img {
  height: 65%;
  width: 65%;
}
