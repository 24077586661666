div.card {
  width: 250px;
  height: 200px;
  text-align: center;
  background: #ada996; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #eaeaea, #dbdbdb, #f2f2f2, #ada996); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #eaeaea, #dbdbdb, #f2f2f2, #ada996); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-box-shadow: 2px 0px 14px 1px rgba(115, 115, 115, 1);
  -moz-box-shadow: 2px 0px 14px 1px rgba(115, 115, 115, 1);
  box-shadow: 2px 0px 14px 1px rgba(115, 115, 115, 1);
}

div.card:hover {
  animation: scale ease-out 0.5s forwards;
}

div.card p {
  text-align: center;
}

div.card h3 {
  white-space: pre-wrap;
}

/* Standard syntax */
@keyframes scale {
  to {
    transform: scale(1.05);
  }
}
